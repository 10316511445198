import { graphql } from "gatsby"
import * as React from "react"
import Layout from "../components/layout"
import { clients, client } from './index.module.css';

// markup
const IndexPage = ({ data }: { data: ClientsQuery }) => {
  const fontSize = '2rem';

  return (
    <Layout>
      <p><span style={{ fontSize }}>🛠</span> Unsere Seite befindet sich momentan noch im Aufbau. <span style={{ fontSize }}>🛠</span></p>
      <h3>Momentane Vorbestellmöglichkeiten:</h3>
      <div className={clients}>
      {data.allClientsJson.edges.map((edge) => (
        <div key={edge.node.name} className={client}>
          <a href={edge.node.url}><img src={edge.node.icon} alt={edge.node.name} /></a>
          <div>
            <h4>{edge.node.name}</h4>
            <p><a href={edge.node.url}>{edge.node.url}</a></p>
          </div>
        </div>
      ))}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query AllClients {
    allClientsJson {
      edges {
        node {
          name
          icon
          url
        }
      }
    }
  }
`;

export default IndexPage
